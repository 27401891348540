import { createEffect, createEvent, createStore, sample } from 'effector';
import { loadScript } from '@/shared/utils/script-load';
import { CMSComponentProperties, ComponentsNames } from '@/shared/app/types';
import { ArenaDataState } from '@/shared/api/arena-data';
import { $isCMPDisabled } from '../analytics/model';
import { $arenaDataStore } from '@/app.model';

export enum AdTypes {
    AD_160x600 = '160,600',
    AD_300x250 = '300,250',
    AD_300x600 = '300,600',
    AD_728x90 = '728,90',
    AD_768x90 = '768,90',
    AD_970x90 = '970,90',
    AD_970x250 = '970,250',
    AD_320x50 = '320,50',
}

export type AdProps = {
    adOptions: AdOptions;
    className?: string;
    hideCaption?: boolean;
};

export type AdDimensionType = `${number},${number}`;
export type AdOptions = {
    __component?: string;
    product: string;
    dimensions: AdTypes | string; //stringified Array<[number, number]>
    'data-id': string;
    'data-testid'?: string;
    id?: string;
};
export interface TAdComponent extends CMSComponentProperties {
    __component: `web-components.${ComponentsNames.AdComponent}`;
    ad_id: string | null;
    dimensions: Array<AdDimensionType> | null;
}

export const getDimensions = (dimensions: Array<AdDimensionType> | null): AdOptions['dimensions'] | null => {
    if (!dimensions) {
        return null;
    }
    return JSON.stringify(
        dimensions.map((dimension) => {
            const [width, height] = dimension.split(',');
            return [Number(width), Number(height)];
        })
    );
};
export const buildTheySellLibPath = (libPath: string, theySellName: string): string => {
    const url = new URL(libPath);
    const parts = url.pathname.split('/');

    parts.splice(parts.length - 1, 0, theySellName);
    url.pathname = parts.join('/');

    return url.href;
};

const initDisplayAdScript = (theySellAd: ArenaDataState['theySellAd']) => {
    let url = process.env.DISPLAY_ADS;
    if (theySellAd) {
        url = buildTheySellLibPath(url, theySellAd.displayAdUrl);
    }
    loadScript(url);
};
export const displayAdStartedFx = createEffect(initDisplayAdScript);
export const $displayAdStarted = createStore(false).on(displayAdStartedFx.done, () => true);

export type TDisplayAdsEmpty = {
    [key: AdOptions['data-id']]: boolean;
};
export const $displayAdsEmpty = createStore<TDisplayAdsEmpty>({});
export const displayAdEmptyUpdated = createEvent<{ id: AdOptions['data-id']; empty: boolean }>();
sample({
    clock: displayAdEmptyUpdated,
    source: $displayAdsEmpty,
    fn: (state, { id, empty }) => ({ ...state, [id]: empty }),
    target: $displayAdsEmpty,
});

sample({
    clock: $isCMPDisabled,
    source: { $displayAdStarted, $arenaDataStore },
    filter: ({ $displayAdStarted }, $isCMPDisabled) =>
        Boolean($isCMPDisabled !== null && !$isCMPDisabled && !$displayAdStarted),
    fn: ({ $arenaDataStore: arenaData }) => {
        return arenaData.theySellAd;
    },
    target: displayAdStartedFx,
});
