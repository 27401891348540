import React, { useContext, useEffect } from 'react';
import styles from './styles.module.css';
import Image from 'next/image';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { $isGDPRApplies, $isUS, openGDPRCmpPopupFx, openUSPCmpPopupFx } from '@/features/analytics/model';
import { $isMicrosoftDomain } from '@/app.model';
import { AnalyticsContextView } from '@/features/arena-data/view';


export type TLink = {
    href: string;
    label: string;
    target: '_blank' | '_self' | '';
    icon: { url: string };
};

export type GroupLinks = {
    link: TLink[];
};

export type TFooter = {
    logo: { url: string } | null;
    groups: GroupLinks[] | null;
    socialLinks: TLink[] | null;
    backgroundColor: string | null;
    fontColor: string | null;
    text: string | null;
};

interface FooterProps {
    attributes: TFooter;
}

const SocialLinks = (socialLinks: TLink[] | null, AITracks: any) => {
    if (!socialLinks) return null;
    return socialLinks.map((link) => (
        <li key={link.href}>
            <a
                href={link.href}
                target={link.target}
                className={styles.socialLink}
                aria-label={link.label}
                onClick={() => AITracks.footerTrackSocialLinkClick(link)}
            >
                <Image className="icon" src={link?.icon?.url} alt={link.label} quality={90} width={24} height={24} />
            </a>
        </li>
    ));
};

const renderGroupLink = (li: TLink, AITracks: any) => (
    <li key={li.href}>
        <a
            href={li.href}
            target={li.target}
            className={styles.groupLink}
            aria-label={li.label}
            onClick={() => AITracks.footerTrackGroupLinkClick(li)}
        >
            <span>{li.label}</span>
            {li?.icon && (
                <Image className="icon" src={li?.icon?.url} alt={li.label} quality={90} width={30} height={14} />
            )}
        </a>
    </li>
);

type TOpenCmpPopup = () => Promise<void>;
const renderMicrosoftGroupLink = (AITracks: any) => {
    const li = {
        href: 'https://aka.ms/YourCaliforniaPrivacyChoices',
        label: 'Your Privacy Choices',
        target: '_blank',
        icon: { url: '/assets/us_privacy_choices_icon.svg' },
    };
    return (
        <li key={li.href}>
            <a
                href={li.href}
                target={li.target}
                className={styles.groupLink}
                aria-label={li.label}
                onClick={() => AITracks.footerTrackMicrosoftClick()}
            >
                <span>{li.label}</span>
                <Image className="icon" src={li.icon.url} alt={li.label} quality={90} width={30} height={14} />
            </a>
        </li>
    );
};
const renderUSGroupLink = (openCmpPopup: TOpenCmpPopup, AITracks: any) => {
    const li = {
        label: 'Your Privacy Choices',
        icon: { url: '/assets/us_privacy_choices_icon.svg' },
    };
    return (
        <li key={li.label}>
            <button
                type="button"
                className={styles.buttonGroupLink}
                aria-label={li.label}
                onClick={() => {
                    AITracks.footerTrackUSPrivacyClick();
                    openCmpPopup();
                }}
            >
                <span>{li.label}</span>
                <Image className="icon" src={li.icon.url} alt={li.label} quality={90} width={30} height={14} />
            </button>
        </li>
    );
};
const renderGDPRGroupLink = (openCmpPopup: TOpenCmpPopup, AITracks: any) => {
    const label = 'Manage Cookies';
    return (
        <li key={label}>
            <button
                type="button"
                className={styles.buttonGroupLink}
                aria-label={label}
                onClick={() => {
                    AITracks.footerTrackGDPRClick();
                    openCmpPopup();
                }}
            >
                <span>{label}</span>
            </button>
        </li>
    );
};

const GroupLinks = (
    groups: GroupLinks[] | null,
    openGDPRCmpPopup: TOpenCmpPopup,
    openUSPCmpPopup: TOpenCmpPopup,
    isGDPRCountry: boolean | null,
    isUS: boolean | null,
    isMicrosoft: boolean,
    AITracks: any
) => {
    if (!groups) return null;

    //prepare html first for all generic links before last group
    const groupsBeforeLast = groups.slice(0, groups.length - 1);
    const groupsBeforeLastUI = groupsBeforeLast.map((ul, index) => (
        <ul key={index}>{ul.link.map((li) => renderGroupLink(li, AITracks))}</ul>
    ));

    //prepare last group links based on geolocation (CMP requirement)
    const lastGroup = groups.slice(-1);
    let customLink = null;
    if (isGDPRCountry) {
        customLink = renderGDPRGroupLink(openGDPRCmpPopup, AITracks);
    } else if (isMicrosoft) {
        customLink = renderMicrosoftGroupLink(AITracks);
    } else if (isUS) {
        customLink = renderUSGroupLink(openUSPCmpPopup, AITracks);
    }

    //render last group
    const lastGroupLinks = lastGroup.map((ul) => (
        <ul key={'last-group'}>
            {ul.link.map((li) => renderGroupLink(li, AITracks))}
            {/* put custom link at the bottom */}
            {customLink}
        </ul>
    ));
    return [...groupsBeforeLastUI, ...lastGroupLinks];
};

export const Footer: React.FC<FooterProps> = ({
    attributes: { logo, backgroundColor, groups, socialLinks, fontColor, text },
}) => {
    const { AITracks } = useContext(AnalyticsContextView);

    const footerTheme = () => {
        return `     
        footer {
            background-color: ${backgroundColor || '#000'}
        }

        footer .container ul li a, 
        footer .container ul li button, 
        footer .container p {
            color: ${fontColor || '#fff'}
        }
            `;
    };

    const currentYear = new Date().getFullYear();

    const imageSrc = () => {
        if (logo?.url == 'undefined' || logo?.url == null) return <></>;
        return <Image src={logo?.url} alt="Company logo" quality={90} width={152} height={40} />;
    };
    const openGDPRCmpPopup = useUnit(openGDPRCmpPopupFx);
    const openUSPCmpPopup = useUnit(openUSPCmpPopupFx);
    const isGDPRCountry = useUnit($isGDPRApplies);
    const isUS = useUnit($isUS);
    const isMicrosoft = useUnit($isMicrosoftDomain);

    useEffect(() => {
        const styleTag = document.createElement('style');
        styleTag.innerHTML = footerTheme();
        document.head.appendChild(styleTag);
        // Cleanup the style tag on unmount to prevent memory leaks
        return () => {
            document.head.removeChild(styleTag);
        };
    }, []);

    return (
        <>
            <footer className={styles.footer}>
                <div className={classNames(styles.container, 'container')}>
                    <div className={styles.block}>
                        {logo && <div className={styles.image}>{imageSrc()}</div>}
                        <p className="margin-16-bottom">
                            {' '}
                            &copy; {currentYear} {text}
                        </p>
                        <div className={styles.social}>
                            <ul className={styles.links}>{SocialLinks(socialLinks, AITracks)}</ul>
                        </div>
                    </div>
                    <div className={styles.blocks}>
                        {GroupLinks(
                            groups,
                            openGDPRCmpPopup,
                            openUSPCmpPopup,
                            isGDPRCountry,
                            isUS,
                            isMicrosoft,
                            AITracks
                        )}
                    </div>
                </div>
            </footer>
        </>
    );
};
