import React, { ReactNode, useState, CSSProperties, useCallback, useEffect, useContext, useRef } from 'react';
import styles from './styles.module.css';
import Image from 'next/image';
import { Search } from '@/features/autocomplete';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import {AnalyticsContextView} from "@/features/arena-data/view";
import { ETabIndexesOrderingLevels } from '@/shared/app/types';

export type TLink = {
    href: string;
    label: string;
    target: '_blank' | '_self' | '';
};

interface TopBarProps {
    zIndexStartVal: number;
    attributes: {
        logo?: string | null | undefined;
        links: TLink[] | null | undefined;
        override_color_topbar_background?: string | null | undefined;
        override_color_item_normal_label?: string | null | undefined;
        override_color_item_hover_background?: string | null | undefined;
    };
    children?: ReactNode;
}

const tabIndexLogo: number = ETabIndexesOrderingLevels.SECOND as unknown as number;
const tabIndexTopbarLinks: number = ETabIndexesOrderingLevels.THIRD as unknown as number;

export const TopBar: React.FC<TopBarProps> = ({
    zIndexStartVal,
    attributes: {
        logo,
        links,
        override_color_topbar_background,
        override_color_item_normal_label,
        override_color_item_hover_background,
    },
    children,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const topbarRef = useRef<HTMLElement>(null);
    const { isNotPc } = useDeviceDetector();
    const {AITracks} = useContext(AnalyticsContextView);

    const topBarStyle = {
        '--background-color': override_color_topbar_background || '',
        zIndex: zIndexStartVal + 1,
    } as CSSProperties;

    const linkStyle: React.CSSProperties = {
        '--normal-label-color': override_color_item_normal_label || '',
        '--hover-background-color': override_color_item_hover_background || '',
    } as CSSProperties;

    const renderedLinks = links?.map((link, index) => (
        <a key={index} href={link.href} target={link.target} onClick={() => AITracks.topNavClick(link?.label ?? '', index)}
            className={['ark-ui-topbar-link', styles.link, '__max12ch'].join(' ')}>
            {link?.label?.substring?.(0, 12) || ''}
        </a>
    ));

    const imageSrc = () => {
        if (logo == 'undefined' || logo == null) return <></>;
        return (
            <Image
                className={`${styles.logo} ${isFocused ? styles.hiddenmobile : ''}`}
                src={logo}
                alt="Logo"
                layout="responsive"
                quality={90}
                width={152}
                height={40}
            />
        );
    };
    function blurSearchElement() {
        const active = document.activeElement;
        if (active?.classList.contains('ark-ui-search-active')) {
            (active as HTMLInputElement).blur();
        }
    }

    useEffect(() => {
        const desktopMql = window.matchMedia('(min-width: 1024px)');
        function tabindexLevelHandler(turnOff = false) {
            document?.body?.querySelector?.(`.${styles.homeLink}`)?.setAttribute('tabindex', tabIndexLogo.toString());
            (document?.body?.querySelectorAll('.ark-ui-topbar.topbar .ark-ui-topbar-link') || []).forEach((el: any) => {
                    el.setAttribute('tabindex', tabIndexTopbarLinks); // first level is for burger icon / sidebar items
            });
        }
        return tabindexLevelHandler(true);
    }, []);

    useEffect(() => {
        if (typeof document === 'undefined' || !topbarRef.current || !isNotPc() || !isFocused) {
            return;
        }

        //setTimeout for iOS scrolling when focusing input - 400 is the CSS transition time
        setTimeout(() => document.addEventListener("scroll", blurSearchElement), 400)
        return () => {
            document.removeEventListener("scroll", blurSearchElement);
        }
    }, [isFocused, topbarRef.current])

    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, []);
    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    return (
        <header ref={topbarRef} className={['ark-ui-topbar topbar', styles.topbar].join(' ')} style={topBarStyle}>
            {logo?.length ? (
                <a className={styles.homeLink} href="/" aria-label="Homepage">
                    {imageSrc()}
                </a>
            ) : (
                <></>
            )}
            <nav style={linkStyle} className={`${styles.links} ${isFocused ? styles.hidden : ''}`}>
                {renderedLinks}
            </nav>
            <div className={`${styles.searchContainer} ${isFocused ? styles.focused : ''}`}>
                <Search onFocus={handleFocus} onBlur={handleBlur} />
            </div>
        </header>
    );
};
